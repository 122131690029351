// import moment from 'moment';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import config from 'config';
import accounting from 'accounting';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { OIDCUserSelector } from '@traveloka/soya-auth';

import { ID, EN } from '../constants/locale';
import { HOUR_MINUTE_FORMAT, DATE_FORMAT } from '../constants/format';
import {
  MONEY_FORMAT,
  DAY_SUNDAY,
  DAY_MONDAY,
  DAY_TUESDAY,
  DAY_WEDNESDAY,
  DAY_THURSDAY,
  DAY_FRIDAY,
  DAY_SATURDAY,
  TRUE_VALUE,
  FALSE_VALUE,
  POINT_TO_POINT_CODE,
  RENTAL_DAILY_CODE,
} from '../constants/constants';

import {
  POINT_TO_POINT_PERMISSIONS,
  RENTAL_DAILY_PERMISSIONS,
} from 'shared/constants/permissions';
import {
  TIMEZONE_CANNONICAL,
} from 'shared/constants/timezone';
import { OIDC_PERMISSION_KEY } from 'shared/constants/oidcToken';

import {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
  MINUTES,
  SECONDS,
  HOURS,
  DAYS,
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  MONTHS,
} from 'translations/date';
import { AGO, JUST_NOW, FEW } from 'translations/common';

// MONEY Related
export const unformatMoney = (text, decimal = MONEY_FORMAT.decimal) =>
  accounting.unformat(text, decimal);

export const formatMoney = (
  text,
  symbol,
  precision = 0,
  decimal = ',',
  thousand = '.'
) => {
  const format = symbol ? '%s %v' : '%v';

  return accounting.formatMoney(Number(text), {
    symbol,
    decimal,
    thousand,
    precision,
    format,
  });
};

export const getSeparatorSymbol = (currency) => {
  if (currency === 'IDR') {
    return {
      thousand: MONEY_FORMAT.thousand,
      decimal: MONEY_FORMAT.decimal,
    };
  }
  return {
    thousand: MONEY_FORMAT.decimal,
    decimal: MONEY_FORMAT.thousand,
  };
};

export const getMoneyMask = (
  prefix,
  decimalLimit,
  separator = { thousand: MONEY_FORMAT.thousand, decimal: MONEY_FORMAT.decimal }
) =>
  createNumberMask({
    prefix,
    decimalLimit: Number(decimalLimit),
    thousandsSeparatorSymbol: separator.thousand,
    decimalSymbol: separator.decimal,
    allowDecimal: decimalLimit > 0,
  });

export const getMask = (params) => {
  const {
    decimalLimit,
    decimalSeparator,
    thousandSeparator,
    allowNegative,
  } = params;

  return createNumberMask({
    prefix: '',
    decimalLimit: Number(decimalLimit),
    thousandsSeparatorSymbol: thousandSeparator,
    decimalSymbol: decimalSeparator,
    allowDecimal: decimalLimit > 0,
    allowNegative,
  });
};

// TIME AND DATE related
// -1 because moment use month numbering from 0 - 11
export const formatDate = (day, month, year, format) =>
  moment([year, month - 1, day]).format(format);

// timestamp in millis
export const formatDateFromTimestamp = (timestamp, format, timezone) => {
  const momentDate = timezone
    ? moment(timestamp).tz(timezone)
    : moment(timestamp);

  return momentDate.format(format);
};

export const formatHourMinuteObject = (hourMinuteObject, format) =>
  formatHourMinute(hourMinuteObject.hour, hourMinuteObject.minute, format);

export const formatHourMinute = (hour, minute, format = HOUR_MINUTE_FORMAT) =>
  moment().hour(hour).minute(minute).format(format);

export const separateDateAndTime = (dateTimeString, dateTimeFormat) => {
  const dateTime = moment(dateTimeString, dateTimeFormat);
  const date = createDateObjectFromMoment(dateTime);
  const time = createHourMinuteObject(dateTime.hour(), dateTime.minute());

  return {
    date,
    time,
  };
};

export const formatDateTimeObjectUTC = (spesificDate, format) => {
  const { monthDayYear, hourMinute } = spesificDate;
  const { month, day, year } = monthDayYear;
  const dateString = formatDate(
    parseInt(day, 10),
    parseInt(month, 10),
    parseInt(year, 10),
    'YYYY-MM-DD'
  );
  // Convert Date to ISO8601 then create new moment object;
  const hourMinuteString = formatHourMinuteObject(hourMinute, 'HH:mm:00');
  const mdyhmJSObj = new Date(`${dateString}T${hourMinuteString}Z`);
  return moment(mdyhmJSObj).format(format);
};

export const isToday = (momentDate) => {
  const dayDifference = momentDate.diff(moment(), 'days');

  return dayDifference === 0;
};

export const isYesterday = (momentDate) => {
  const dayDifference = momentDate.diff(moment(), 'days');

  return dayDifference === -1;
};

export const isTomorrow = (momentDate) => {
  const dayDifference = momentDate.diff(moment(), 'days');

  return dayDifference === 1;
};

export const createDateObject = (day, month, year) => ({
  day,
  month,
  year,
});

export const createDateObjectFromMoment = (momentDate) =>
  createDateObject(
    momentDate.date(),
    momentDate.month() + 1, // + 1 because moment month start from 0 to 11
    momentDate.year()
  );

export const getDateObject = (date, format = DATE_FORMAT) => {
  const momentDate = moment(date, format);

  return createDateObjectFromMoment(momentDate);
};

export const createTimeObject = (hour, minute, second) => ({
  hour,
  minute,
  second,
});

export const createHourMinuteObject = (hour, minute) => ({
  hour,
  minute,
});

export const createTimeObjectFromMoment = (momentDate) =>
  createTimeObject(momentDate.hour(), momentDate.minute(), momentDate.second());

export const createTimeObjectFromMinutes = (minutes) =>
  createTimeObject(Math.floor(minutes / 60), minutes % 60, 0);

export const getMonthString = (month, locale = EN) => {
  switch (month) {
    case 1:
      return JANUARY[locale];
    case 2:
      return FEBRUARY[locale];
    case 3:
      return MARCH[locale];
    case 4:
      return APRIL[locale];
    case 5:
      return MAY[locale];
    case 6:
      return JUNE[locale];
    case 7:
      return JULY[locale];
    case 8:
      return AUGUST[locale];
    case 9:
      return SEPTEMBER[locale];
    case 10:
      return OCTOBER[locale];
    case 11:
      return NOVEMBER[locale];
    case 12:
      return DECEMBER[locale];
    default:
      return null;
  }
};

export const getDayOfWeek = (date, format) => {
  const dayOfWeekIndex = moment(date, format).day();

  switch (dayOfWeekIndex) {
    case 0:
      return DAY_SUNDAY;
    case 1:
      return DAY_MONDAY;
    case 2:
      return DAY_TUESDAY;
    case 3:
      return DAY_WEDNESDAY;
    case 4:
      return DAY_THURSDAY;
    case 5:
      return DAY_FRIDAY;
    case 6:
      return DAY_SATURDAY;
    default:
      return DAY_SUNDAY;
  }
};

export const getDifferenceTimeFromNow = (msTimestamp, unitOfTime) => {
  return moment().diff(moment(msTimestamp), unitOfTime);
};

// @deprecated, its better that we return the translation key and not do the translation inside the function
export const getDifferenceTimeFromNowLabel = (msTimestamp, locale = EN) => {
  const msDiffTime = moment().diff(moment(msTimestamp), 'miliseconds');
  let differenceTime = JUST_NOW[locale];

  if (msDiffTime < 60000) {
    differenceTime = `${getDifferenceTimeFromNow(msTimestamp, 'seconds')} ${SECONDS[locale]
      } ${AGO[locale]}`;
  } else if (msDiffTime < 3600000) {
    differenceTime = `${getDifferenceTimeFromNow(msTimestamp, 'minutes')} ${MINUTES[locale]
      } ${AGO[locale]}`;
  } else if (msDiffTime < 86400000) {
    differenceTime = `${getDifferenceTimeFromNow(msTimestamp, 'hours')} ${HOURS[locale]
      } ${AGO[locale]}`;
  } else if (msDiffTime < 2592000000) {
    differenceTime = `${getDifferenceTimeFromNow(msTimestamp, 'days')} ${DAYS[locale]
      } ${AGO[locale]}`;
  } else if (msDiffTime > 2627900000) {
    differenceTime = `${getDifferenceTimeFromNow(msTimestamp, 'month')} ${MONTHS[locale]
      } ${AGO[locale]}`;
  } else if (msDiffTime > 5256100000) {
    differenceTime = `${FEW[locale]} ${MONTHS[locale]} ${AGO[locale]}`;
  }

  return differenceTime;
};

export const getMonthStartAndEndDateObject = (month, year) => {
  return {
    startDate: createDateObject(1, month, year),
    endDate: createDateObject(
      moment()
        .month(month - 1)
        .year(year)
        .endOf('month')
        .date(),
      month,
      year
    ),
  };
};

export const getWeekStartAndEndDate = (
  dateString,
  dateFormat = DATE_FORMAT
) => {
  const weekStartDate = moment(dateString, dateFormat).startOf('week');
  const weekEndDate = moment(dateString, dateFormat).endOf('week');

  return {
    startDate: weekStartDate,
    endDate: weekEndDate,
  };
};

// Timezone
export const convertEtcToGmtTimezoneFormat = (etcFormat) => {
  const gmtFormat = moment.tz(etcFormat.toString()).format('Z');
  const convertedGmt = `GMT${gmtFormat}`;

  return convertedGmt;
};

export const convertGmtToEtcTimezoneFormat = (gmtFormat) => {
  let formatedGmt;

  const cannocicalTimezone = TIMEZONE_CANNONICAL[gmtFormat];
  if (cannocicalTimezone) {
    return cannocicalTimezone;
  }

  if (gmtFormat.includes('+')) {
    formatedGmt = gmtFormat.replace('+', '-');
  } else if (gmtFormat.includes('-')) {
    formatedGmt = gmtFormat.replace('-', '+');
  }

  const splittedString = formatedGmt.split(':');
  const gmt = splittedString[0].substring(0, 4);
  const gmtNumber = Number(splittedString[0].substring(4, 6));

  const convertedEtc = `Etc/${gmt}${gmtNumber}`;

  return convertedEtc;
};

// MODIFY TEXT related
// TODO: Change name to enumToString
export const toTitleCase = (text = '', splitSymbol = '_') => {
  if (!text) {
    return '';
  }

  return text
    .toLowerCase()
    .split(splitSymbol)
    .map((splitText) => splitText.charAt(0).toUpperCase() + splitText.slice(1))
    .join(' ');
};

export const toTitleCaseDaysOfWeek = (daysOfWeek) => {
  const joinedDaysOfWeek = daysOfWeek.join(', ');

  return toTitleCase(joinedDaysOfWeek, ' ');
};

// @deprecated, use translation directly, eg: t('sunday')
export const getDayTranslation = (day, locale) => {
  switch (day) {
    case 'SUNDAY':
      return SUNDAY[locale];
    case 'MONDAY':
      return MONDAY[locale];
    case 'TUESDAY':
      return TUESDAY[locale];
    case 'WEDNESDAY':
      return WEDNESDAY[locale];
    case 'THURSDAY':
      return THURSDAY[locale];
    case 'FRIDAY':
      return FRIDAY[locale];
    case 'SATURDAY':
      return SATURDAY[locale];
    default:
      return '';
  }
};

// @deprecated, use translation directly, eg: t('sunday')
export const getDaysTranslation = (days, locale = EN) => {
  const translatedDays = [];

  days.forEach((day) => translatedDays.push(getDayTranslation(day, locale)));

  return translatedDays;
};

// API related
export const getBackendLocale = (locale) => {
  return locale.toLowerCase() + '_' + ID.toUpperCase();
};

export const getHostname = (url) => {
  let hostname;

  if (url.indexOf('://') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const getGlobalParams = () => {
  return config.get('globalParams') || {};
};

// PHONE NUMBER related
export const formatPhoneNumber = (phone, countryCode = '+62') => {
  return String(countryCode) + String(phone);
};

export const removePhoneCountryCode = (phone, countryCode = '+62') => {
  const phoneString = phone ? String(phone) : '';

  const hasCountryCode = phoneString.indexOf(countryCode) >= 0;

  if (hasCountryCode) {
    return phoneString.replace(countryCode, '');
  }

  if (phone && phone[0] === '0') {
    return phoneString.slice(1);
  }

  return phone;
};

// PERMISSIONS related
export const getPermissionsFromState = (state) => {
  const oidcSelector = OIDCUserSelector(state);
  const token = oidcSelector.getToken();

  const permissions =
    token && token.tokenBody
      ? Array.from(
        new Set([
          ...(JSON.parse(token.tokenBody[OIDC_PERMISSION_KEY]) || []),
          ...(token.tokenBody.permissions || []),
        ])
      )
      : [];

  return permissions;
};

export const hasPermission = (permissions, permission) => {
  return !permission || permissions.includes(permission);
};

export const handleRequiredPermissionsByWebMode = (
  permissions,
  webMode = null
) => {
  switch (webMode) {
    case POINT_TO_POINT_CODE:
      return [...permissions, POINT_TO_POINT_PERMISSIONS];
    case RENTAL_DAILY_CODE:
      return [...permissions, RENTAL_DAILY_PERMISSIONS];
    default:
      return permissions;
  }
};

// MODIFY VALUE related
export const parseBooleanConstant = (value) => {
  switch (value) {
    case TRUE_VALUE:
      return true;
    case FALSE_VALUE:
      return false;
    default:
      return null;
  }
};

export const parseBooleanValueToConstant = (value) => {
  switch (value) {
    case true:
      return TRUE_VALUE;
    case false:
      return FALSE_VALUE;
    default:
      return null;
  }
};

export const getUserGroups = (state) => {
  const oidcSelector = OIDCUserSelector(state);
  const token = oidcSelector.getToken();
  const userGroup =
    token && token.tokenBody ? token.tokenBody['https://tvlk/groups'] : [];

  return userGroup;
};

export default {
  unformatMoney,
  formatMoney,
  formatDate,
  formatDateFromTimestamp,
  formatHourMinuteObject,
  formatHourMinute,
  getDateObject,
  createDateObject,
  createDateObjectFromMoment,
  createTimeObject,
  createTimeObjectFromMinutes,
  getWeekStartAndEndDate,
  getMonthStartAndEndDateObject,
  getDayOfWeek,
  getBackendLocale,
  toTitleCase,
  getHostname,
  formatPhoneNumber,
  removePhoneCountryCode,
  getPermissionsFromState,
  hasPermission,
  parseBooleanConstant,
  parseBooleanValueToConstant,
};
