export const TYPE_GMT_PLUS_ZERO = 'GMT+00:00';
export const TYPE_GMT_PLUS_ONE = 'GMT+01:00';
export const TYPE_GMT_PLUS_TWO = 'GMT+02:00';
export const TYPE_GMT_PLUS_THREE = 'GMT+03:00';
export const TYPE_GMT_PLUS_FOUR = 'GMT+04:00';
export const TYPE_GMT_PLUS_FOUR_THIRTY = 'GMT+04:30';
export const TYPE_GMT_PLUS_FIVE = 'GMT+05:00';
export const TYPE_GMT_PLUS_FIVE_THIRTY = 'GMT+05:30';
export const TYPE_GMT_PLUS_FIVE_FOURTY_FIVE = 'GMT+05:45';
export const TYPE_GMT_PLUS_SIX = 'GMT+06:00';
export const TYPE_GMT_PLUS_SIX_THIRTY = 'GMT+06:30';
export const TYPE_GMT_PLUS_SEVEN = 'GMT+07:00';
export const TYPE_GMT_PLUS_EIGHT = 'GMT+08:00';
export const TYPE_GMT_PLUS_NINE = 'GMT+09:00';
export const TYPE_GMT_PLUS_NINE_THIRTY = 'GMT+09:30';
export const TYPE_GMT_PLUS_TEN = 'GMT+10:00';
export const TYPE_GMT_PLUS_TEN_THIRTY = 'GMT+10:30';
export const TYPE_GMT_PLUS_ELEVEN = 'GMT+11:00';
export const TYPE_GMT_PLUS_TWELVE = 'GMT+12:00';
export const TYPE_GMT_PLUS_TWELVE_FOURTY_FIVE = 'GMT+12:45';
export const TYPE_GMT_PLUS_THIRTEEN = 'GMT+13:00';
export const TYPE_GMT_PLUS_FOURTEEN = 'GMT+14:00';
export const TYPE_GMT_MINUS_TWELVE = 'GMT-12:00';
export const TYPE_GMT_MINUS_ELEVEN = 'GMT-11:00';
export const TYPE_GMT_MINUS_TEN = 'GMT-10:00';
export const TYPE_GMT_MINUS_NINE_THIRTY = 'GMT-09:30';
export const TYPE_GMT_MINUS_NINE = 'GMT-09:00';
export const TYPE_GMT_MINUS_EIGHT = 'GMT-08:00';
export const TYPE_GMT_MINUS_SEVEN = 'GMT-07:00';
export const TYPE_GMT_MINUS_SIX = 'GMT-06:00';
export const TYPE_GMT_MINUS_FIVE = 'GMT-05:00';
export const TYPE_GMT_MINUS_FOUR = 'GMT-04:00';
export const TYPE_GMT_MINUS_THREE = 'GMT-03:00';
export const TYPE_GMT_MINUS_TWO = 'GMT-02:00';
export const TYPE_GMT_MINUS_ONE = 'GMT-01:00';
export const TYPE_GMT_MINUS_ZERO = 'GMT-00:00';

export const TIMEZONE_SELECTION = [
  { value: TYPE_GMT_PLUS_ZERO, label: TYPE_GMT_PLUS_ZERO },
  { value: TYPE_GMT_PLUS_ONE, label: TYPE_GMT_PLUS_ONE },
  { value: TYPE_GMT_PLUS_TWO, label: TYPE_GMT_PLUS_TWO },
  { value: TYPE_GMT_PLUS_THREE, label: TYPE_GMT_PLUS_THREE },
  { value: TYPE_GMT_PLUS_FOUR, label: TYPE_GMT_PLUS_FOUR },
  { value: TYPE_GMT_PLUS_FOUR_THIRTY, label: TYPE_GMT_PLUS_FOUR_THIRTY },
  { value: TYPE_GMT_PLUS_FIVE, label: TYPE_GMT_PLUS_FIVE },
  { value: TYPE_GMT_PLUS_FIVE_THIRTY, label: TYPE_GMT_PLUS_FIVE_THIRTY },
  { value: TYPE_GMT_PLUS_FIVE_FOURTY_FIVE, label: TYPE_GMT_PLUS_FIVE_FOURTY_FIVE },
  { value: TYPE_GMT_PLUS_SIX, label: TYPE_GMT_PLUS_SIX },
  { value: TYPE_GMT_PLUS_SIX_THIRTY, label: TYPE_GMT_PLUS_SIX_THIRTY },
  { value: TYPE_GMT_PLUS_SEVEN, label: TYPE_GMT_PLUS_SEVEN },
  { value: TYPE_GMT_PLUS_EIGHT, label: TYPE_GMT_PLUS_EIGHT },
  { value: TYPE_GMT_PLUS_NINE, label: TYPE_GMT_PLUS_NINE },
  { value: TYPE_GMT_PLUS_NINE_THIRTY, label: TYPE_GMT_PLUS_NINE_THIRTY },
  { value: TYPE_GMT_PLUS_TEN, label: TYPE_GMT_PLUS_TEN },
  { value: TYPE_GMT_PLUS_TEN_THIRTY, label: TYPE_GMT_PLUS_TEN_THIRTY },
  { value: TYPE_GMT_PLUS_ELEVEN, label: TYPE_GMT_PLUS_ELEVEN },
  { value: TYPE_GMT_PLUS_TWELVE, label: TYPE_GMT_PLUS_TWELVE },
  { value: TYPE_GMT_PLUS_TWELVE_FOURTY_FIVE, label: TYPE_GMT_PLUS_TWELVE_FOURTY_FIVE },
  { value: TYPE_GMT_PLUS_THIRTEEN, label: TYPE_GMT_PLUS_THIRTEEN },
  { value: TYPE_GMT_PLUS_FOURTEEN, label: TYPE_GMT_PLUS_FOURTEEN },
  { value: TYPE_GMT_MINUS_TWELVE, label: TYPE_GMT_MINUS_TWELVE },
  { value: TYPE_GMT_MINUS_ELEVEN, label: TYPE_GMT_MINUS_ELEVEN },
  { value: TYPE_GMT_MINUS_TEN, label: TYPE_GMT_MINUS_TEN },
  { value: TYPE_GMT_MINUS_NINE_THIRTY, label: TYPE_GMT_MINUS_NINE_THIRTY },
  { value: TYPE_GMT_MINUS_NINE, label: TYPE_GMT_MINUS_NINE },
  { value: TYPE_GMT_MINUS_EIGHT, label: TYPE_GMT_MINUS_EIGHT },
  { value: TYPE_GMT_MINUS_SEVEN, label: TYPE_GMT_MINUS_SEVEN },
  { value: TYPE_GMT_MINUS_SIX, label: TYPE_GMT_MINUS_SIX },
  { value: TYPE_GMT_MINUS_FIVE, label: TYPE_GMT_MINUS_FIVE },
  { value: TYPE_GMT_MINUS_FOUR, label: TYPE_GMT_MINUS_FOUR },
  { value: TYPE_GMT_MINUS_THREE, label: TYPE_GMT_MINUS_THREE },
  { value: TYPE_GMT_MINUS_TWO, label: TYPE_GMT_MINUS_TWO },
  { value: TYPE_GMT_MINUS_ONE, label: TYPE_GMT_MINUS_ONE },
  { value: TYPE_GMT_MINUS_ZERO, label: TYPE_GMT_MINUS_ZERO },
];

export const TIMEZONE_CANNONICAL = {
  [TYPE_GMT_PLUS_FOUR_THIRTY]: 'Asia/Kabul',
  [TYPE_GMT_PLUS_FIVE_THIRTY]: 'Asia/Colombo',
  [TYPE_GMT_PLUS_FIVE_FOURTY_FIVE]: 'Asia/Kathmandu',
  [TYPE_GMT_PLUS_SIX_THIRTY]: 'Asia/Rangoon',
  [TYPE_GMT_PLUS_NINE_THIRTY]: 'Australia/Adelaide',
  [TYPE_GMT_PLUS_TEN_THIRTY]: 'Australia/LHI',
  [TYPE_GMT_PLUS_TWELVE_FOURTY_FIVE]: 'Pacific/Chatham',
  [TYPE_GMT_MINUS_NINE_THIRTY]: 'Pacific/Marquesas'
}