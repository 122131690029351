import jwtDecode from 'jwt-decode';

import { hasPermission } from './Helper';

import { POINT_TO_POINT_CODE, RENTAL_DAILY_CODE, ACCESS_TOKEN_KEY } from 'shared/constants/constants';
import { POINT_TO_POINT_PERMISSIONS, RENTAL_DAILY_PERMISSIONS } from 'shared/constants/permissions';

export const getTokenExpired = () => {
  const auth0JwtToken = getLocalStorageAccessToken();

  if (!auth0JwtToken) {
    return ;
  }
  
  const parsedToken = jwtDecode(auth0JwtToken);

  return parsedToken.exp;
}

export const getLocalStorageAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export const setClientState = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getClientState = (key) => {
  const value = localStorage.getItem(key) 

  return JSON.parse(value);
};

export const removeClientState = (key) => {
  localStorage.removeItem(key);
};

export const setClientSessionState = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getClientSessionState = (key) => {
  const value = sessionStorage.getItem(key) 

  return JSON.parse(value);
};

export const removeClientSessionState = (key) => {
  sessionStorage.removeItem(key);
};

export const clearClientSessionState = (key) => {
  sessionStorage.clear(); 
};

/** Local Storage with auth0 expired time */
export const setUserClientState = (key, value) => {
  const exp = getTokenExpired();

  setClientState(key, { _value: value, exp });
};

export const getUserClientState = (key) => {
  const value = getClientState(key);

  if (!value) {
    removeClientState(key);

    return null;
  }

  const { _value, exp } = value;

  if (!exp || exp !== getTokenExpired()) {
    removeClientState(key);

    return null;
  }

  return _value;
};
/** Local Storage with auth0 expired time */

/** Session Storage with auth0 expired time */
export const setUserClientSessionState = (key, value) => {
  const exp = getTokenExpired();

  setClientSessionState(key, { _value: value, exp });
};

export const getUserClientSessionState = (key) => {
  const value = getClientSessionState(key);

  if (!value) {
    removeClientSessionState(key);

    return null;
  }

  const { _value, exp } = value;

  if (!exp || exp !== getTokenExpired()) {
    removeClientSessionState(key);

    return null;
  }

  return _value;
};
/** Session Storage with auth0 expired time */

export const getWebModes = (userPermissions) => {
  const webModes = [];

  if (hasPermission(userPermissions, POINT_TO_POINT_PERMISSIONS)) {
    webModes.push(POINT_TO_POINT_CODE);
  }

  if (hasPermission(userPermissions, RENTAL_DAILY_PERMISSIONS)) {
    webModes.push(RENTAL_DAILY_CODE);
  }

  return webModes;
}

export const getWebModeFromUrl = (url) => {
  if (url.indexOf(`/${POINT_TO_POINT_CODE}`) === 0) {
    return POINT_TO_POINT_CODE;
  }

  if (url.indexOf(`/${RENTAL_DAILY_CODE}`) === 0) {
    return RENTAL_DAILY_CODE;
  }

  return null;
}
