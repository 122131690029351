export const TIMEZONE = {
  minute: 7 * 60,
  hour: 7,
};

export const DAY_SUNDAY = 'SUNDAY';
export const DAY_MONDAY = 'MONDAY';
export const DAY_TUESDAY = 'TUESDAY';
export const DAY_WEDNESDAY = 'WEDNESDAY';
export const DAY_THURSDAY = 'THURSDAY';
export const DAY_FRIDAY = 'FRIDAY';
export const DAY_SATURDAY = 'SATURDAY';

export const DAYS_OF_WEEK = [
  DAY_SUNDAY,
  DAY_MONDAY,
  DAY_TUESDAY,
  DAY_WEDNESDAY,
  DAY_THURSDAY,
  DAY_FRIDAY,
  DAY_SATURDAY,
];

export const QUERY_SEARCH_MINIMAL_STRING = 3;

export const DEFAULT_WHT_PERCENTAGE = 2.0;
export const DEFAULT_VAT_PERCENTAGE = 10.0;

export const MONEY_FORMAT = {
  decimal: ',',
  thousand: '.',
  precision: 2,
};

export const COORDINATE_FORMAT = {
  decimal: '.',
  thousand: ',',
  decimalPoint: 8,
};

export const TRUE_VALUE = 'true';
export const FALSE_VALUE = 'false';

export const DEFAULT_PHONE_COUNTRY_CODE = '+62';

export const QUERY_NULL_SYMBOL = '-';

export const POINT_TO_POINT_CODE = 'p2p'; // if change then folder pages/p2p must be change
export const RENTAL_DAILY_CODE = 'rd'; // if change then folder pages/rd must be change

export const WEB_MODE_COOKIE = 'txt_web_mode';

export const TRAVELOKA_EMAIL_DOMAIN = '@traveloka.com';

export const SUCCESS = 'success';
export const FAILED = 'failed';
export const IN_PROGRESS = 'in progress';

export const INFINITE_SYMBOL = '\u221E';

export const ACCESS_TOKEN_KEY = 'access_token';

export const FIREBASE_TOKEN_KEY = 'firebaseToken';
