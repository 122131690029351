export const AU = 'AU';
export const CN = 'CN';
export const DE = 'DE';
export const ID = 'ID';
export const MY = 'MY';
export const NL = 'NL';
export const PH = 'PH';
export const SG = 'SG';
export const TH = 'TH';
export const US = 'US';
export const VN = 'VN';
export const JP = 'JP';
export const KR = 'KR';

// Language
export const EN = 'EN';
export const MS = 'MS';
export const VI = 'VI';
export const ZH = 'ZH'; // Chinese
export const JA = 'JA'; // Japanese
export const KO = 'KO'; // Korean

export const DEFAULT_LOCALE = EN;

export const EN_ID = `${EN.toLowerCase()}_${ID}`;
export const EN_EN = `${EN.toLowerCase()}_${EN}`;
export const ID_ID = `${ID.toLowerCase()}_${ID}`;
export const EN_PH = `${EN.toLowerCase()}_${PH}`;
export const EN_MY = `${EN.toLowerCase()}_${MY}`;
export const MS_MY = `${MS.toLowerCase()}_${MY}`;
export const EN_SG = `${EN.toLowerCase()}_${SG}`;
export const EN_TH = `${EN.toLowerCase()}_${TH}`;
export const TH_TH = `${TH.toLowerCase()}_${TH}`;
export const EN_VN = `${EN.toLowerCase()}_${VN}`;
export const VI_VN = `${VI.toLowerCase()}_${VN}`;
export const EN_AU = `${EN.toLowerCase()}_${AU}`;
// JP/KR
export const JA_JP = `${JA.toLowerCase()}_${JP}`;
export const KO_KR = `${KO.toLowerCase()}_${KR}`;
export const EN_KR = `${EN.toLowerCase()}_${KR}`;
export const EN_JP = `${EN.toLowerCase()}_${JP}`;
