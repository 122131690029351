import { EN, ID } from 'shared/constants/locale';

export const JANUARY = {
  [EN]: 'January',
  [ID]: 'Januari',
};

export const FEBRUARY = {
  [EN]: 'February',
  [ID]: 'Februari',
};

export const MARCH = {
  [EN]: 'March',
  [ID]: 'Maret',
};

export const APRIL = {
  [EN]: 'April',
  [ID]: 'April',
};

export const MAY = {
  [EN]: 'May',
  [ID]: 'Mei',
};

export const JUNE = {
  [EN]: 'June',
  [ID]: 'Juni',
};

export const JULY = {
  [EN]: 'July',
  [ID]: 'Juli',
};

export const AUGUST = {
  [EN]: 'August',
  [ID]: 'Agustus',
};

export const SEPTEMBER = {
  [EN]: 'September',
  [ID]: 'September',
};

export const OCTOBER = {
  [EN]: 'October',
  [ID]: 'Oktober',
};

export const NOVEMBER = {
  [EN]: 'November',
  [ID]: 'November',
};

export const DECEMBER = {
  [EN]: 'December',
  [ID]: 'Desember',
};

export const YEAR_S = {
  [EN]: 'Year(s)',
  [ID]: 'Tahun',
};

export const MONTH_S = {
  [EN]: 'Month(s)',
  [ID]: 'Bulan',
};

export const HOURS = {
  [EN]: 'Hours',
  [ID]: 'Jam',
};

export const HOUR_S = {
  [EN]: 'Hour(s)',
  [ID]: 'Jam',
};

export const MINUTES = {
  [EN]: 'Minutes',
  [ID]: 'Menit',
};

export const MINUTE_S = {
  [EN]: 'Minute(s)',
  [ID]: 'Menit',
};

export const SECONDS = {
  [EN]: 'Seconds',
  [ID]: 'Detik',
};

export const DAYS = {
  [EN]: 'Days',
  [ID]: 'Hari',
};

export const MONTHS = {
  [EN]: 'Months',
  [ID]: 'Bulan',
};

export const DAY_S = {
  [EN]: 'Day(s)',
  [ID]: 'Hari',
};

export const SUNDAY = {
  [EN]: 'Sunday',
  [ID]: 'Minggu',
};

export const MONDAY = {
  [EN]: 'Monday',
  [ID]: 'Senin',
};

export const TUESDAY = {
  [EN]: 'Tuesday',
  [ID]: 'Selasa',
};

export const WEDNESDAY = {
  [EN]: 'Wednesday',
  [ID]: 'Rabu',
};

export const THURSDAY = {
  [EN]: 'Thursday',
  [ID]: 'Kamis',
};

export const FRIDAY = {
  [EN]: 'Friday',
  [ID]: 'Jumat',
};

export const SATURDAY = {
  [EN]: 'Saturday',
  [ID]: 'Sabtu',
};
